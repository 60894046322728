import { Item } from '@/types/commodity';

export const OSS_HOST = 'https://everygrowth-small.oss-accelerate.aliyuncs.com';
export const OSS_PREFIX = OSS_HOST + '/manual/modules/swts/pc';
export const LOGO = OSS_PREFIX + '/logo.png';
export const QRCODE_1 = OSS_PREFIX + '/qrcode1.jpg';
export const QRCODE_2 = OSS_PREFIX + '/qrcode2.jpg';
export const QRCODE_TOP = OSS_PREFIX + '/qrcode/top.jpg';

export const ROUTE_NAME = {
    INDEX: 'index',
    DETAIL: 'detail',
    EXPLORE: 'explore',
};

export const COMMODITY: {
    [type: string]: Item[];
} = {
    hufu: [
        { id: 'hufu_time', title: '时间极简系列',disabled:true, text: 'EURONA 氨基酸净透洁颜露', img: require('@/assets/images/hufu_time.jpg') },
        { id: 'hufu_1', title: '时间逆转系列', text: 'EURONA 4D立体抗衰喷雾', img: OSS_PREFIX + '/hufu_1.jpg' },
        { id: 'hufu_3', text: 'EURONA 铂金松露精华液', img: OSS_PREFIX + '/hufu_3.jpg' },
    ],
    gufa: [
        { id: 'gufa_1', title: '护发系列', text: 'Naköa多肽护发精华液', img: OSS_PREFIX + '/gufa_1.jpg', disabled: true, },
    ],
    jiankang: [
        // { id: 'jiankang_1', title: '时间欢兴系列', text: '塞万提斯特级初榨橄榄油', img: OSS_PREFIX + '/jiankang_1.jpg' },
        { id: 'jiankang_2', text: '劳丹老藤葡萄酒', img: OSS_PREFIX + '/jiankang_2.jpg' },
    ],
    tansuo: [
        { id: 'tansuo_1', title: '关于公司', text: '敢于创新的国际品牌基因', img: OSS_PREFIX + '/tansuo/tansuo_1.jpg' },
        { id: 'tansuo_2', title: '时间复兴', text: '探索人、科技、与时间的艺术之美', img: OSS_PREFIX + '/tansuo/tansuo_2.jpg' },
        { id: 'tansuo_3', title: '科技与匠心', text: '严谨的工匠精神，追求完美', img: OSS_PREFIX + '/tansuo/tansuo_3.jpg' },
        { id: 'tansuo_4', title: '时间行动派', text: '用行动铸造伟大', img: OSS_PREFIX + '/tansuo/tansuo_4.jpg' },
    ],
};

export const COMMODITY_DETAIL: {
    [id: string]: string[];
} = {
    hufu_1: [
        OSS_PREFIX + '/slide_1.jpg',
        OSS_PREFIX + '/hufu/hufu_1_1.jpg',
        OSS_PREFIX + '/hufu/hufu_1_2.jpg',
    ],
    hufu_2: [
        OSS_PREFIX + '/hufu/hufu_2_1.jpg',
        OSS_PREFIX + '/hufu/hufu_2_2.jpg',
        OSS_PREFIX + '/hufu/hufu_2_3.jpg',
    ],
    hufu_3: [
        OSS_PREFIX + '/hufu/hufu_3_1.jpg',
        OSS_PREFIX + '/hufu/hufu_3_2.jpg',
        OSS_PREFIX + '/hufu/hufu_3_3.jpg',
    ],
    gufa_1: [
        OSS_PREFIX + '/gufa/gufa_1_1_1.jpg',
        OSS_PREFIX + '/gufa/gufa_1_2_1.jpg',
        OSS_PREFIX + '/gufa/gufa_1_3.jpg',
    ],
    jiankang_1: [
        OSS_PREFIX + '/jiankang/jiankang_1_1.jpg',
        OSS_PREFIX + '/jiankang/jiankang_1_2.jpg',
        OSS_PREFIX + '/jiankang/jiankang_1_3.jpg',
    ],
    jiankang_2: [
        OSS_PREFIX + '/jiankang/jiankang_2_1.jpg',
        OSS_PREFIX + '/jiankang/jiankang_2_2.jpg',
        OSS_PREFIX + '/jiankang/jiankang_2_3.jpg',
    ],
    tansuo_1: [
        OSS_PREFIX + '/tansuo/tansuo_1_1.jpg',
        OSS_PREFIX + '/tansuo/tansuo_1_2.jpg',
        OSS_PREFIX + '/tansuo/tansuo_1_3.jpg',
        OSS_PREFIX + '/tansuo/tansuo_1_4.jpg',
        OSS_PREFIX + '/tansuo/tansuo_1_5.jpg',
        OSS_PREFIX + '/tansuo/tansuo_1_6.jpg',
    ],
    tansuo_2: [
        OSS_PREFIX + '/tansuo/tansuo_2_1.jpg',
        OSS_PREFIX + '/tansuo/tansuo_2_2.jpg',
        OSS_PREFIX + '/tansuo/tansuo_2_3.jpg',
        OSS_PREFIX + '/tansuo/tansuo_2_4.jpg',
        OSS_PREFIX + '/tansuo/tansuo_2_5.jpg',
    ],
    tansuo_3: [
        OSS_PREFIX + '/tansuo/tansuo_3_1.jpg',
        OSS_PREFIX + '/tansuo/tansuo_3_2.jpg',
        OSS_PREFIX + '/tansuo/tansuo_3_3.jpg',
        OSS_PREFIX + '/tansuo/tansuo_3_4.jpg',
        OSS_PREFIX + '/tansuo/tansuo_3_5.jpg',
    ],
    tansuo_4: [
        OSS_PREFIX + '/tansuo/tansuo_4_1.jpg',
        OSS_PREFIX + '/tansuo/tansuo_4_2.jpg',
        // OSS_PREFIX + '/tansuo/tansuo_4_3.png',
    ],
};
