import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import { ROUTE_NAME } from '@/lib/constant';
import store from '@/store';

Vue.use(VueRouter)

const routes: RouteConfig[] = [
    {
        path: '/',
        name: ROUTE_NAME.INDEX,
        component: () => import(/* webpackChunkName: 'index' */ '@/pages/index.vue'),
    },
    {
        path: '/detail/:id',
        name: ROUTE_NAME.DETAIL,
        component: () => import(/* webpackChunkName: 'index' */ '@/pages/detail.vue'),
    },
    {
        path: '/explore',
        name: ROUTE_NAME.EXPLORE,
        component: () => import(/* webpackChunkName: 'index' */ '@/pages/explore.vue'),
    },
]

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, saved) {
        if (to.name === ROUTE_NAME.DETAIL) {
            return {
                x: saved ? saved.x : 0,
                y: 0,
            };
        } else {
            return saved || { x: 0, y: 0 };
        }
    },
});

router.beforeEach((to: Route, from: Route, next: any) => {
    store.commit('util/setQrcode', '');
    next();
});

export default router;
