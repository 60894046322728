



























































import { Component, Vue } from 'vue-property-decorator';
import { LOGO, QRCODE_1, QRCODE_TOP } from '@/lib/constant';

@Component({
    name: 'LayoutFooter',
})
export default class LayoutFooter extends Vue {
    public logo = LOGO;
    public qrcode1 = QRCODE_1;
    public qrcode2 = QRCODE_TOP;
    public country = 'china';
}
