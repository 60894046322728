import Vue from 'vue';
import { Module } from 'vuex';
import { RootState } from '@/types/store';

export interface UtilState {
    qrcode: string;
}

const m: Module<UtilState, RootState> = {
    namespaced: true,
    state: {
        qrcode: '',
    },
    mutations: {
        setQrcode(state, qrcode) {
            state.qrcode = qrcode;
        },
    },
};

export default m;
