













import { Component, Vue } from 'vue-property-decorator';
import store from './store';

@Component({
    name: 'App',
})
export default class App extends Vue {
    public get qrcode() {
        return store.state.util.qrcode;
    }
}
