import Vue from 'vue';
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import Qrcode from '@/components/qrcode.vue';
import { Carousel, CarouselItem } from 'element-ui';

export default function extendVue() {
    Vue.component('LayoutHeader', Header);
    Vue.component('LayoutFooter', Footer);
    Vue.component('Qrcode', Qrcode);
    Vue.component(Carousel.name, Carousel);
    Vue.component(CarouselItem.name, CarouselItem);
}
