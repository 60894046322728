
































import { Component, Vue } from 'vue-property-decorator';
import { LOGO, QRCODE_TOP, ROUTE_NAME, COMMODITY } from '@/lib/constant';
import { Item } from '@/types/commodity';

@Component({
    name: 'LayoutHeader',
})
export default class LayoutHeader extends Vue {
    public logo = LOGO;
    public qrcode = QRCODE_TOP;
    public showDropdown = false;
    public showDetail = false;
    public detailType = '';

    public detail = COMMODITY;
    public left = 0;

    public get selectedDetail() {
        return this.detail[this.detailType] || [];
    }

    public onClickIndex() {
        this.$router.push({
            name: ROUTE_NAME.INDEX,
        });
    }
    public onClickItem(type: string) {
        if (type === this.detailType) {
            this.detailType = '';
            this.showDetail = false;
        } else {
            this.detailType = type;
            this.showDetail = true;
        }

        this.showDropdown = false;
    }
    public onClickClub() {
        this.showDropdown = !this.showDropdown;
        this.showDetail = false;
        this.detailType = '';
    }
    public onClickDetailItem(item: Item) {
        if (item.disabled) {
            return;
        }
        this.onClickItem(this.detailType);
        this.$router.push({
            name: ROUTE_NAME.DETAIL,
            params: {
                id: item.id,
            },
        });
    }
    public onClickExplore() {
        this.$router.push({
            name: ROUTE_NAME.EXPLORE,
        });
    }
    public scrollCallback() {
        this.left = document.documentElement.scrollLeft;
    }
    public tryClose(e: MouseEvent) {
        if (e.target && !this.$el.contains(e.target as HTMLElement)) {
            this.showDetail = false;
            this.detailType = '';
        }
    }

    public mounted() {
        window.addEventListener('scroll', this.scrollCallback);
        window.addEventListener('resize', this.scrollCallback);

        this.scrollCallback();

        window.addEventListener('click', this.tryClose);
    }
    public beforeDestroy() {
        window.removeEventListener('scroll', this.scrollCallback);
        window.removeEventListener('resize', this.scrollCallback);
        window.removeEventListener('click', this.tryClose);
    }
}
