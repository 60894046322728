















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'Qrcode',
})
export default class Qrcode extends Vue {
    @Prop({ type: String }) public text1!: string;
    @Prop({ type: String }) public text2!: string;
    @Prop({ required: true, type: String }) public qrcode!: string;
    @Prop({ type: String }) public text3!: string;
}
