import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import extendVue from '@/lib/extendVue';
import { Component } from 'vue-property-decorator';

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteUpdate',
    'beforeRouteLeave',
]);

extendVue();

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
